<template>
  <FHButton
    class="c-file-element u-mb"
    defaultStyles
    :href="content.file_type.file.url"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div class="c-file-element__title">
      {{ content.file_type.name }}
    </div>
    <div class="c-dbok__desc" v-md-html="content.file_type.description" />
  </FHButton>
</template>

<script>
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    FHButton,
  },

  props: {
    content: Object,
  },
}
</script>

<style lang="scss">
.c-file-element {
  display: block;
  max-width: 35%;
}
.c-file-element__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}
.c-file-element__desc {
  p {
    margin-bottom: 0;
  }
}
</style>
