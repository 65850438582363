<template>
  <div class="c-tag u-pl">
    #{{ label }}
  </div>
</template>

<script>
export default {
  props: {
    label: String
  }
}
</script>

<style lang="scss">
.c-tag {
  display: inline;
  font-size: .75rem;
}
</style>