<template>
  <div v-if="lesson" :class="['c-lesson']">
    <UnitHeader
      :class="`c-lesson_header--${unit.slug}`"
      v-if="unit && unit.slug"
      :unitSlug="unit.slug"
      type="lesson-plans"
      :element="lesson.name"
    />

    <div class="c-lesson__tags u-py">
      <Tag v-for="tag in lesson.keywords" :key="tag._id" :label="tag.label" />
    </div>

    <VHeading header="h1" class="c-lesson__title u-px">
      <LessonplanIconBig class="c-lesson__title-icon" />
      {{ lesson.name }}
    </VHeading>
    <div class="c-lesson__wrapper u-px--3">
      <aside class="c-lesson__col u-px">
        <div class="c-lesson__aims-container">
          <VHeading
            header="h3"
            tabindex="0"
            class="c-lesson__aims-title"
            :class="{ 'c-lesson__aims-title--opened': showCA }"
            @click.native="showCA = !showCA"
            @keyup.enter.native="showCA = !showCA"
          >
            {{ $t('LESSON_COMPETENCY_AIMS') }}
          </VHeading>
          <ul
            class="c-lesson__aims"
            :class="{ 'c-lesson__aims--opened': showCA }"
          >
            <li
              v-for="aim in lesson.competencies"
              :key="aim._id"
              class="c-lesson__aim"
            >
              {{ aim.name }}
            </li>
          </ul>
        </div>

        <div v-if="lesson.duration" class="c-lesson__schooltime">
          <TimeIcon class="c-lesson__schooltime-icon" />
          {{ lesson.duration.minValue
          }}{{
            lesson.duration.maxValue ? ' - ' + lesson.duration.maxValue : ''
          }}
          {{ $t(lesson.duration.durationUnit.toUpperCase()) }}
        </div>

        <div v-if="learningElements" class="c-lesson__learning-elements">
          <component
            v-for="item in learningElements.items"
            class="c-lesson__learning-element"
            :key="item._id"
            :is="componentNameNormalize(item.__component)"
            :content="item"
            :unit="unit.slug"
          />
        </div>
      </aside>

      <main class="c-lesson__col u-px">
        <div v-if="lesson.learningGoals" class="c-lesson__goals">
          <div
            v-for="goal in lesson.learningGoals"
            :key="goal.id"
            class="c-lesson__goal"
          >
            <VHeading
              header="h2"
              class="c-lesson__goal-title"
              :text="goal.Label"
            >
            </VHeading>
            <div class="c-lesson__goal-body" v-md-html="goal.Content" />
          </div>
        </div>

        <div v-if="lessonPlan" class="c-lesson__lesson-plan u-mb">
          <LessonPlanSection
            v-for="item in lessonPlan.items"
            class="c-lesson__lesson-plan-section"
            :key="item._id"
            :content="item"
          />
        </div>

        <component
          v-for="element in files"
          :key="element.id"
          :is="componentNameNormalize(element.__component)"
          :content="element"
        />
      </main>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { parseLearningElements, componentNameNormalize } from '@/utils'
import { fetchLesson } from '@/services/strapi'
import VHeading from '@forlagshuset/v-heading'
import LessonplanIconBig from '@/assets/svg/Icon_Lessonplan_Big'
import TimeIcon from '@/assets/svg/Icon_Time'
import UnitHeader from '@/components/UnitHeader'
import Tag from '@/components/Tag'
import LessonPlanSection from '@/components/LessonPlanSection'
import ComponentLearningElementTextBox from '@/components/LearningElements/TextBox'
import ComponentLearningElementTextElement from '@/components/LearningElements/TextElement'
import ComponentLearningElementArticleElement from '@/components/LearningElements/ArticleElement'
import ComponentLearningElementSectionTagElement from '@/components/LearningElements/SectionTagElement'
import ComponentLearningElementDbokLinkElement from '@/components/LearningElements/DbokLinkElement'
import ComponentLearningElementFileElement from '@/components/LearningElements/FileElement'
import ComponentLearningElementActivityElement from '@/components/LearningElements/ActivityElement'

export default {
  components: {
    LessonplanIconBig,
    TimeIcon,
    VHeading,
    UnitHeader,
    Tag,
    LessonPlanSection,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementArticleElement,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementDbokLinkElement,
    ComponentLearningElementFileElement,
    ComponentLearningElementActivityElement,
  },

  setup(props, ctx) {
    const appSlug = computed(
      () => ctx.root.$options.router.history.current.params.lessonSlug,
    )
    const lesson = ref({})
    const learningElements = ref([])
    const lessonPlan = ref([])
    const files = ref([])
    const showCA = ref(false)
    const unit = ref({})

    onMounted(async () => {
      let parsed
      lesson.value = await fetchLesson(appSlug.value)

      await ctx.root.$store.dispatch(
        'content/setCourseUnits',
        process.env.VUE_APP_APPSLUG,
      )
      const units = ctx.root.$store.getters['content/getCourseUnits'].filter(
        unit => !unit.teacherUnit,
      )
      unit.value = units.filter(
        unit => lesson.value.tags[0].id === unit.tags[0].id,
      )[0]

      parsed = parseLearningElements(lesson.value.scenario)
      learningElements.value = parsed.filter(
        element => componentNameNormalize(element.__component) === 'LeftMargin',
      )[0]
      lessonPlan.value = parsed.filter(
        element => componentNameNormalize(element.__component) === 'LessonPlan',
      )[0]
      files.value = parsed.filter(
        element =>
          componentNameNormalize(element.__component) ===
          'ComponentLearningElementFileElement',
      )
    })

    return {
      appSlug,
      lesson,
      learningElements,
      lessonPlan,
      componentNameNormalize,
      files,
      showCA,
      unit,
    }
  },
}
</script>

<style lang="scss">
.c-lesson {
  min-height: calc(100vw - 20.5rem);
  .c-lesson_header--echo-1-identity {
    .c-unit__header {
      background-color: $unit-identity-bg;
    }
  }
  .c-lesson_header--echo-2-english-around-the-world {
    .c-unit__header {
      background-color: $unit-english-bg;
    }
  }
  .c-lesson_header--echo-3-citizenship {
    .c-unit__header {
      background-color: $unit-citizenship-bg;
    }
  }
  .c-lesson_header--echo-4-indigenous-peoples {
    .c-unit__header {
      background-color: $unit-indigenous-bg;
    }
  }
  .c-lesson_header--echo-my-grammar {
    .c-unit__header {
      background-color: $unit-grammar-bg;
    }
  }
}

.c-unit__header {
  @include bp-down($medium) {
    display: none;
  }
}

.c-lesson__tags {
  width: 25%;
  display: inline-block;
  text-align: right;
  padding-left: 4rem;

  @include bp-down($medium) {
    display: none;
  }
}

.c-lesson__title {
  display: inline-block;
  vertical-align: top;

  h1 {
    font-size: 1.5rem;
  }

  .c-lesson__title-icon {
    vertical-align: middle;
    padding-right: 0.5rem;
  }
}

.c-lesson__wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;

  @include bp-down($medium) {
    flex-direction: column;
  }
}

.c-lesson__col {
  display: flex;
  flex-direction: column;
  &:first-child {
    @include bp($large) {
      max-width: 350px;
    }
  }
  &:last-child {
    max-width: $article-width;
    border-left: 1px solid $color-divider;
    @include bp-down($medium) {
      border-left: none;
      border-top: 1px solid $color-divider;
    }
  }

  .c-lesson__goal-title h2,
  .c-lesson__lesson-plan-section-title h2 {
    font-size: 1.125rem;
    text-transform: uppercase;
  }
  .c-lesson__aims {
    display: none;
    &--opened {
      display: block;
    }
  }
  .c-lesson__aims-title h3,
  .c-text-element__title h3,
  .c-lesson__learning-element h2 {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }
}
.c-lesson__aims-title {
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    border: solid #000;
    border-width: 0 0.15rem 0.15rem 0;
    padding: 0 0.25rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0.5rem;
    margin: 0 0.75rem 0 0.25rem;
  }
  &--opened {
    &:before {
      margin-top: -0.125rem;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
.c-lesson__aims,
.c-text-box__body ul {
  list-style: none none;
  padding: 0;
  margin: 0;
}
.c-text-box__body {
  p {
    margin: 0.25rem 0;
  }
}
.c-lesson__aim,
.c-text-box__body ul li {
  padding: 0;
}
.c-lesson__schooltime {
  margin-top: 0.25rem;
  line-height: 1.5;
  .c-lesson__schooltime-icon {
    vertical-align: bottom;
    height: 1.5rem;
  }
}
</style>
